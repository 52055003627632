import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/popper.js.org/popper.js.org/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "react-hook",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#react-hook",
        "aria-label": "react hook permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`React Hook`}</h1>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`usePopper`}</code>{` hook provides an API almost identical to the ones of
`}<a parentName="p" {...{
        "href": "../../../docs/v2/constructors/#createpopper"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`createPopper`}</code></a>{` constructor.`}</p>
    <p>{`Rather than returning a Popper instance, it will return an object containing the
following properties:`}</p>
    <x-ad />
    <h3 {...{
      "id": "styles",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#styles",
        "aria-label": "styles permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><code parentName="h3" {...{
        "className": "language-text"
      }}>{`styles`}</code></h3>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`styles`}</code>{` property is an object, its properties are `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`popper`}</code>{`, and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`arrow`}</code>{`.
The two properties are a
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/CSSStyleDeclaration"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`CSSStyleDeclaration`}</code>{` interface`}</a>{`
describing the necessary CSS properties needed to properly position the two
elements.`}</p>
    <p>{`You can directly assign the value of the two properties to the React `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`style`}</code>{`
property of your components.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`style`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`styles`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`popper`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span></code></pre></div>
    <h3 {...{
      "id": "attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#attributes",
        "aria-label": "attributes permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><code parentName="h3" {...{
        "className": "language-text"
      }}>{`attributes`}</code></h3>
    <p>{`Similar to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`styles`}</code>{`, the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`attributes`}</code>{` object lists the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`popper`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`arrow`}</code>{` HTML
attributes, by default, only `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`popper`}</code>{` will hold some attributes (e.g.
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`data-popper-placement`}</code>{`), but more generically, any HTML attribute described by
the Popper documentation will be available inside these properties.`}</p>
    <p>{`The easiest way to consume their values is by destructuring them directly onto
your React component.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token spread"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`...`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}>{`attributes`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}>{`popper`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span></code></pre></div>
    <h3 {...{
      "id": "update-forceupdate-and-state",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#update-forceupdate-and-state",
        "aria-label": "update forceupdate and state permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><code parentName="h3" {...{
        "className": "language-text"
      }}>{`update`}</code>{`, `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`forceUpdate`}</code>{`, and `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`state`}</code></h3>
    <p>{`These properties match the ones described in the
`}<a parentName="p" {...{
        "href": "../../../docs/v2/constructors/#types"
      }}>{`Popper docs`}</a>{`, the only difference is that
they can be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`null`}</code>{` if Popper isn't yet been initialized or has been destroyed.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      